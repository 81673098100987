import React, { useEffect, useState } from "react";
import intelytics from "../../../assets/intelytics.png";
import retailpro from "../../../assets/retail_pro.png";
import LogoText from "assets/svg_logo_text_white.svg"
import search_icon from "../../../assets/search_icon.png";
import notifications_icon from "../../../assets/notifications_icon.png";
import logout from "../../../assets/logout.png";
import moon from "../../../assets/moon.png";
import sun from "../../../assets/sun.png";
import notify_filled from "../../../assets/notify_filled.png";
import logout_black from "../../../assets/logout_black.png";
import { logOut } from "utils/helpers/common";
import { Select } from "../../Select";
import { getCookie } from "services/session/cookies";
import { logoutAPI } from "services/api/auth";
import { InputField } from "components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "services/api/common";
import { REQUEST_TYPES, ENDPOINTS } from "services/middleware/url";

const Header = ({ changeThemeColor, ThemeColor, pathname }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [language, setLanguage] = useState("");
  const [t, i18n] = useTranslation("global");
  const slectedLanguage = useSelector((state) => state?.common?.Language);

  const onchangeHandler = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
    dispatch(ChangeLanguage(value));
  };

  useEffect(() => {
    try {
      const user = JSON.parse(getCookie("user" ? "user" : {}));
      setUser(user);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logoutAPI(REQUEST_TYPES.POST, ENDPOINTS.LOGOUT, "logout"));
    logOut();
    navigate("/login");
  };

  return (
    <div
      className={`flex justify-between pt-4 pb-4 fixed top-0 w-full z-40 bg-black`}
    >
      <div className="w-2/12 pl-14 flex items-center">
        <img src={LogoText} alt="logo" className="w-full object-cover" />
      </div>
      <div className="w-6/12 flex justify-end pr-10 items-center">
        <div className={`flex justify-end items-center pl-2 text-white`}>
          <div
            className={`pl-2 ${pathname === "/main-dashboard" ? "pr-2" : ""}`}
          >
            {/* <Select
              id="20"
              label=""
              className={`w-full h-8  outline-none paragraphs_font field_bg-blue  text-sm  pl-1 pr-5 text-white border font-normal language-select`}
              changeHandler={(event) => onchangeHandler(event.target.value)}
              value={slectedLanguage}
              name="usertype"
              options={[
                {
                  label: "English (US)",
                  value: "en",
                },
                {
                  label: "Arabic",
                  value: "ar",
                },
              ]}
              required={false}
            /> */}
          </div>
          <div className="flex items-center">
            <div>
              <img
                src={notifications_icon}
                alt="noitfy"
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="pl-5 pr-2">
            <div className="capitalize">
              {user?.userName ? user?.userName : "User Name"}
            </div>
          </div>
          <div>
            <img
              src={logout}
              alt="logout"
              className="cursor-pointer"
              onClick={() => {
                logoutHandler();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
