import React, { useCallback, useState, useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAP_API_KEY } from 'utils/constants/constatns';
import ToolTip from './Tooltip';

const libraries = ['places'];
const mapContainerStyle = {
  flexGrow: 1,
  height: '100%'
};
const containerStyle = {
  display: 'flex',
  height: '100%',
  width: '100%',
  position: 'relative'
};
const sidebarStyle = {
  width: "400px",
  height: '100%',
  overflowY: 'auto',
  backgroundColor: '#ffffff',
  padding: '0px 10px',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'none',
  zIndex: 10,
  boxShadow: "4px 0px 13px 1px rgba(0, 0, 0, 0.1)"
};
const visibleSidebarStyle = {
  ...sidebarStyle,
  display: 'block'
};

const options = {
  disableDefaultUI: true,
  zoomControl: true
};

export const MarkerMap = ({ data = [] }) => {
  const center = {
    lat: (data && data.length &&  data[0]?.Latitude) ? Number(data[0]?.Latitude) : 33.7182,
    lng: (data && data.length &&  data[0]?.Longitude) ? Number(data[0]?.Longitude) : 73.0714
  };

  let locations = []
  
  locations = data && data.length && data.map((item,index) => {
    return {
      lat: item?.Latitude ? Number(item?.Latitude) : "",
      lng: item?.Longitude ? Number(item?.Longitude) : "",
      additionalInfo: item
    }
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [selected, setSelected] = useState(null);
  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSelected(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onMapClick = useCallback(() => {
    setSelected(null);
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <div style={containerStyle}>
      <div
        ref={sidebarRef}
        style={selected ? visibleSidebarStyle : sidebarStyle}
      >
        {selected && (
          <ToolTip location={selected} />
        )}
      </div>
      {(locations && locations.length) ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13} // Increase zoom level
          center={center}
          options={options}
          onClick={onMapClick}
        >
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
              icon={{
                path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
                fillColor: "red",
                fillOpacity: 1,
                strokeColor: "red",
                strokeWeight: 2,
                scale: 2, // Increase marker size
              }}
              onClick={() => {
                setSelected(location);
              }}
            />
          ))}
        </GoogleMap>
      ) : (
        ""
      )}
    </div>
  );
};


