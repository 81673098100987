import dayjs from "dayjs";
import { formatDateToDateMonth } from "./common";
// import {
//     setKey,
//     geocode,
//     RequestType,
//     setDefaults
// } from "react-geocode";
import { REACT_APP_GOOGLE_MAP_API_KEY } from "utils/constants/constatns";


// setDefaults({
//     key: REACT_APP_GOOGLE_MAP_API_KEY,
//     language: "en",
//     region: "es",
// });
// setKey(REACT_APP_GOOGLE_MAP_API_KEY);


export const consumedDataManipulation = (data) => {
    let plannedDays = 0;
    let remainingDays = 0;
    let endDays = 0;

    data?.map(item => {
        plannedDays += item.planned_days;
        remainingDays += item.remaining_days;
        endDays += item.end_days;
    })

    return ({
        plannedDays,
        remainingDays,
        endDays,
        percentageRemaning: (endDays * 100) / plannedDays || 0
    })
}

export const getDynamicDates = () => {
    const currentDate = new Date();
    const currentYearStart = new Date(currentDate.getFullYear(), 0, 1);

    return [
        dayjs(currentYearStart),
        dayjs(currentDate)
    ];
};

export const CityBreakDown = (Data) => {
    let City = []
    let productive_knocks = []
    let achieved_knocks = []
    let products_sold = []
    let samples_given = []
    let deal_given = []
    let demo_given = []

    Data?.map((item) => {
        City.push(item.name)
        productive_knocks.push(item.productive_knocks)
        achieved_knocks.push(item.achieved_knocks)
        products_sold.push(item.products_sold)
        deal_given.push(item.deal_given)
        samples_given.push(item.samples_given)
        demo_given.push(item.demo_given)
    })

    return {
        City,
        productive_knocks,
        achieved_knocks,
        products_sold,
        samples_given,
        deal_given,
        demo_given
    }
}

export const TargetChart = (Data) => {
    let Result = [];
    let target = 0;
    let reach = 0;
    let percentage = 0;
    Data?.map((item) => {
        let record = {
            Date: `${formatDateToDateMonth(item.date)}`,
            Target: item.daily_target || 0,
            Reach: item.achieved_target
        };
        target += item.program_target || item.activity_target
        reach += item.achieved_target
        Result.push(record);
    })
    percentage = (reach * 100) / target
    const tart = {
        target,
        reach,
        percentage
    }
    return { Result, tart };
}

export const ReachTrialFormatter = (Data) => {
    let Result = [];
    Data?.map((item) => {
        let record = { month: item.monthName, Reach: item.reach, Trial: item.trial, Conversion: item.converted };
        Result.push(record);
    })
    return Result;
}


export const CityLatitude = (Data) => {
    let Result = [];
    Data?.map((item) => {
        if (item.userId) {
            Result.push({
                Y: item.latitude || 0,
                X: item.longitude || 0,
                osm_id: item?.city_name,
                value: 1
            })
        }
    });

    return Result
}



export const generateOptionsFromProducts = (products) => {
    const xAxisData = products.map(product => product.name); // Get product names for x-axis
    const seriesData = [];

    products.forEach((product, productIndex) => {
        product.SKUs.forEach((sku, skuIndex) => {
            // Ensure there's a series object for each SKU index
            if (!seriesData[skuIndex]) {
                seriesData[skuIndex] = {
                    name: `SKU ${skuIndex + 1}`,
                    type: 'bar',
                    stack: 'product',
                    data: []
                };
            }

            // Add the SKU data to the corresponding series
            seriesData[skuIndex].data[productIndex] = {
                value: sku.value,
                itemStyle: { color: getRandomColor() },
                name: sku.name
            };
        });
    });

    // Return the complete options object
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                let tooltipText = '';
                params.forEach(item => {
                    const name = item.data?.name || item.seriesName;  // Safe check for item.data.name
                    tooltipText += `${name}: ${item.value}<br/>`;
                });
                return `${params[0]?.axisValue}<br/>${tooltipText}`;
            }
        },
        legend: {
            show: false,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: xAxisData
        },
        yAxis: {
            type: 'value'
        },
        series: seriesData
    };
}


export const SumOfPercentage = (data) => {
    let Total = 0;

    data?.map((item) => {
        Total += item.percentage;
    });

    return Total
}

export const SumOfSales = (data) => {
    let Total = 0;

    data?.map((item) => {
        Total += item.quantity;
    });

    return Total
}

// Function to generate a random hex color
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const GetSalesChart = (Data) => {

    let Result = [];


    Data?.map((item) => {
        Result.push({
            name: item?.name,
            type: 'bar',
            stack: 'total',
            label: {
                show: false
            },
            emphasis: {
                focus: 'series'
            },
            color: getRandomColor(),
            data: [item?.quantity],
        });
    });

    return Result;
};

export const GetBreakDownChart = (Data) => {

    let Result = [];
    let ResultName = [];


    Data?.map((item) => {
        Result.push({ value: item?.usage, name: item?.name });
        ResultName.push(item?.name)
    });

    return { Result, ResultName };
};

export const GetGiftChart = (Data) => {

    let Result = [];


    Data?.map((item) => {
        Result.push({ value: item?.quantity, name: item?.name });
    });

    return Result;
};


export const GetDealsChart = (Data) => {
    let Result = [];

    // Function to generate a random hex color
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    Data?.map((item) => {
        Result.push({
            value: item.quantity,
            name: item.name,
            itemStyle: { color: getRandomColor() }
        });
    });

    return Result;
};



export const GetCompetitionBrandChart = (Data) => {

    let Result = [];


    Data?.map((item) => {
        Result.push({
            name: item?.name,
            type: 'bar',
            stack: 'total',
            label: {
                show: false
            },
            emphasis: {
                focus: 'series'
            },
            color: getRandomColor(),
            data: [item?.usage],
            perValue: item?.percentage,
        });
    });

    return Result;
};




export const getValueLabel = (Data, value) => {
    let Result = [];
    Data?.map((item, index) => {
        Result.push({
            value: item.id, label: item[value]
        })
    })

    return Result
}