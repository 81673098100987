import delete_icon from "assets/delete_icon.png";

export const TypeCheckBox = ({
  item,
  index,
  handleChange,
  changeData,
  addNewOption,
  deleteItem,
  handleChangeOption,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex flex-col">
          <label className="mb-1 mt-2 paragraphs_font text-sm">
            Field Label
          </label>
          <input
            type="text"
            value={item?.label}
            name="label"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font"
            onChange={(e) => {
              handleChange(index, e);
            }}
            onBlur={() => {
              changeData();
            }}
          />
        </div>
      </div>

      <div className="flex pt-3 w-9/12 justify-between items-center">
        <label className="mb-2 mt-2 paragraphs_font text-sm">Check Box</label>
        <div className="text-blue-500 text-sm">
          <button
            onClick={() => {
              addNewOption("checkBox", index);
            }}
          >
            Add More
          </button>
        </div>
      </div>

      {item?.checkBoxButtons.map((opt, optIndex) => {
        return (
          <div className="mb-2 flex items-center">
            <input
              type="text"
              value={opt?.label}
              name="label"
              className="opt-input px-2 py-1 rounded paragraphs_font"
              onChange={(e) => {
                handleChangeOption("checkBox", index, optIndex, e);
              }}
              onBlur={() => {
                changeData();
              }}
            />
            <span
              className="ml-3 cursor-pointer"
              onClick={() => {
                deleteItem("checkBox", index, optIndex);
              }}
            >
              <img src={delete_icon} alt="delete" />
            </span>
          </div>
        );
      })}
    </>
  );
};
