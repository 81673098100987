import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "components";

export const CheckBox = ({
  className = "form-check",
  type = "",
  name = "",
  id = "",
  disabled = false,
  required = false,
  value = false,
  label = "",
  changeHandler,
  errorMessage = "",
  hidden,
}) => {
  return (
    <div className="flex">
      <div className="switch_box box_1">
        <label className="form-check-label-inline" htmlFor={`${type}`}>
          <input
            type={hidden ? "hidden" : type}
            className="form-check-input switch_1"
            name={name}
            id={id}
            onChange={changeHandler}
            disabled={disabled}
            required={required}
            checked={value}
          />
          {label}
          {required ? "*" : ""}
        </label>
        {errorMessage ? (
          <span>
            <ErrorMessage message={errorMessage} />
          </span>
        ) : (
          ""
        )}
      </div>
      {!hidden ? (
        <div>
          {value === true ? (
            <p className="text-green-600 mrt-2">Active</p>
          ) : (
            <p className="checkBox_value_color mrt-2">Inactive</p>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
CheckBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
