import { useState, useEffect } from "react";
import { Select } from "../../Select";

export const ConditionalLogic = ({
  item,
  index,
  datasource,
  setDataSource,
  selectedField,
  originalData,
  itemType,
}) => {
  const [parent, setParent] = useState(item?.parentId ? item?.parentId : "");
  const [state, setState] = useState(item?.ConditionalLogic);
  const [parentOption, setParentOption] = useState([]);
  const [selectedParentOption, setSelectedParentOption] = useState(
    item?.parentValue ? item?.parentValue : ""
  );
  const [isHide, setIsHide] = useState(item?.show ? item?.show : "");
  const selectFields = datasource.filter(
    (field) =>
      (field.id !== item.id && field.fieldType === "select") ||
      (field.id !== item.id && field.fieldType === "radio") ||
      (field.id !== item.id && field.fieldType === "checkBox")
  );

  const getAllFields = selectFields.length
    ? selectFields.map((single) => {
      return { label: single.label, value: single.id };
    })
    : [{ label: "Parent Field Not Available", value: "" }];

  // useEffect(() => {
  //   const getField = datasource.filter((field) => {
  //     if (field.id === Number(parent)) {
  //       return field;
  //     }
  //   });
  //   if (parent !== "" && getField.lenght) {
  //     if (
  //       getField[0]?.options ||
  //       getField[0]?.radioButtons ||
  //       getField[0]?.checkBoxButtons
  //     ) {
  //       let options = getField[0]?.options
  //         ? getField[0]?.options
  //         : getField[0]?.radioButtons || getField[0]?.checkBoxButtons;
  //       setParentOption(options);
  //       setIsDisabled(false);
  //     }
  //   } else {
  //     setParentOption([]);
  //     setIsDisabled(true);
  //   }
  // }, []);
  useEffect(() => {
    const getField = datasource.filter((field) => field.id === Number(parent));
    if (parent !== "" && getField.length) {
      if (
        getField[0]?.options ||
        getField[0]?.radioButtons ||
        getField[0]?.checkBoxButtons
      ) {
        const options = getField[0]?.options
          ? getField[0]?.options
          : getField[0]?.radioButtons || getField[0]?.checkBoxButtons;
        setParentOption(options);
        setIsDisabled(false);
      }
    } else {
      setParentOption([]);
      setIsDisabled(true);
    }
  }, [parent, datasource]); // Make sure to include `parent` and `datasource` in dependencies.

  const parentSelectHandler = (currentItemIndex, event, key) => {
    if (event.target.value !== "Please select from the following") {
      setParent(event.target.value);
      const selectedField = datasource.filter(
        (item) => item.id == event.target.value
      );

      const updatedOriginalData = [...originalData];
      updatedOriginalData.forEach((group) => {
        if (group.fields[currentItemIndex]?.key == key) {
          group.fields[currentItemIndex].parentId = event.target.value;
        }
      });
      setDataSource(updatedOriginalData);

      if (
        selectedField[0].options ||
        selectedField[0].radioButtons ||
        selectedField[0]?.checkBoxButtons
      ) {
        let options = selectedField[0].options
          ? selectedField[0].options
          : selectedField[0].radioButtons || selectedField[0].checkBoxButtons;
        setParentOption(options);
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setParent(event.target.value);
      setParentOption([]);
    }
  };

  const handleParentValue = (currentItemIndex, event, key) => {
    const updatedOriginalData = [...originalData];
    updatedOriginalData.forEach((group) => {
      if (group.fields[currentItemIndex]?.key == key) {
        group.fields[currentItemIndex].parentValue = event.target.value;
      }
    });
    setDataSource(updatedOriginalData);
    setSelectedParentOption(event.target.value);
  };

  const handleHideShow = (currentItemIndex, event, key) => {
    let value = event.target.value === "Hide" ? false : true;
    setIsHide(event.target.value);
    const updatedOriginalData = [...originalData];
    updatedOriginalData.forEach((group) => {
      if (group.fields[currentItemIndex]?.key == key) {
        group.fields[currentItemIndex].parentId = parent;
        group.fields[currentItemIndex].parentValue = selectedParentOption;
        group.fields[currentItemIndex].show = value;
      }
    });
    setDataSource(updatedOriginalData);
  };

  // const handleChange = (currentItemIndex, e) => {
  //   setState(e.target.checked);
  //   const updatedOriginalData = [...originalData];
  //   updatedOriginalData[0].fields[currentItemIndex].ConditionalLogic =
  //     e.target.checked;
  //   setDataSource(updatedOriginalData);
  // };

  const handleChange = (currentItemIndex, e, key) => {
    setState(e.target.checked);
    const updatedOriginalData = [...originalData];
    updatedOriginalData.forEach((group) => {
      if (group.fields[currentItemIndex]?.key == key) {
        group.fields[currentItemIndex].ConditionalLogic = e.target.checked;
      }
    });
    setDataSource(updatedOriginalData);
  };



  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div className=" pt-4">
      <div className="flex">
        <div class="wrapper w-10">
          <div class="switch_box box_1 ">
            <input
              type="checkbox"
              class="switch_11"
              name="isRequired"
              checked={state}
              onChange={(e) => handleChange(index, e, item.key)}
            />
          </div>
        </div>
        <label className="margin-label paragraphs_font text-sm">
          Conditional Logic
        </label>
      </div>
      <div className="flex items-center pt-4 w-2/5">
        <div>
          <label className="mb-1 mt-2 paragraphs_font">Parent Field</label>
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            changeHandler={(event) => parentSelectHandler(index, event, item.key)}
            value={parent}
            name="parent"
            disabled={state ? false : true}
            options={
              selectFields.length
                ? [
                    { label: "Please select from the following", value: "" },
                    ...getAllFields,
                  ]
                : getAllFields
            }
            required={true}
          />
        </div>
        <div className="mt-6 ml-2">
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            value={selectedParentOption}
            name="parent"
            options={[
              { label: "Please select from the following", value: "" },
              ...parentOption,
            ]}
            disabled={state ? false : true}
            required={true}
            changeHandler={(event) => handleParentValue(index, event, item.key)}
          />
        </div>
        <div className="mt-6 ml-2">
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            value={isHide}
            disabled={state ? false : true}
            name="parent"
            options={[
              { label: "Choose to Hide and Show", value: "" },
              ...[
                { label: "Hide", value: false },
                { label: "Show", value: true },
              ],
            ]}
            required={true}
            changeHandler={(event) => handleHideShow(index, event, item.key)}
          />
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <input
          type="checkbox"
          id="email"
          name="email"
          value="email"
          disabled={state ? false : true}
          className="mr-1 cursor-pointer"
        />
        <label for="email" className="text-xs paragraphs_font">
          Send e-mail notification
        </label>
      </div>
    </div>
  );
};
