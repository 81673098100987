import React, { useMemo, useState } from "react";
import { Tooltip } from "antd";

export const GenericTooltip = ({ placement, title, arrow, children, className}) => {
  const [arrowState, setArrowState] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrowState === "Hide") {
      return false;
    }
    if (arrowState === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrowState]);

  return (
    <Tooltip
      placement={placement}
      title={title}
      arrow={arrow || mergedArrow}
      className={className}
    >
      {children}
    </Tooltip>
  );
};
