import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from 'redux-thunk';
import rootReducer from "./Reducers";
import { persistStore } from 'redux-persist';

//thunk middleware is used to intercept actions so as to make API call before dispatching result to reducer
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export const persistor = persistStore(store);

export default store;
