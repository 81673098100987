import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";
import { GETCITY, GETGEOFENCE } from "../actions/Types";

export default function CommonReducer(state = StoreState.common, action) {
  switch (action.type) {
    case ActionType.THEME:
      var s = cloneDeep(state);
      s.Theme = action.data;
      return s;
    case ActionType.SELECTEDLANGUAGE:
      var s1 = cloneDeep(state);
      s1.Language = action.data;
      return s1;
    case ActionType.GETCITY:
      var s2 = cloneDeep(state);
      s2.city = action.data;
      return s2;
    case ActionType.GETGEOFENCE:
      var s3 = cloneDeep(state);
      s3.geoFence = action.data;
      return s3;
    case ActionType.GETPOP:
      var s3 = cloneDeep(state);
      s3.pop = action.data;
      return s3;
    default:
      return state;
  }
}
