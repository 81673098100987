import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EyeFilled from "assets/EyeFilled.svg";
import moment from "moment";
import ArrowDownIconComp from 'components/iconComponents/ArrowDown';

const ToolTip = ({ location }) => {
  const [state, setState] = useState([])
  const ThemeColor = useSelector((state) => state?.common?.Theme);

  useEffect(() => {
    const formData = location && location.additionalInfo && location.additionalInfo.responseDatajson && location.additionalInfo.responseDatajson;
    const parsedFormData = JSON.parse(formData);
    setState(parsedFormData?.data)
  },[location])

  return (
    <div>
      <div className='reporting_tooltip_content'>
        <h4>Record No:</h4>
        <h5>{location.additionalInfo.formId}</h5>
      </div>

      <div className='reporting_tooltip_content' style={{borderBottom: "1px solid #E5E9EB"}}>
        <h4>Name</h4>
        <h5>{`${location?.additionalInfo?.first_name} ${location.additionalInfo.last_name}`}</h5>
      </div>

      <button className="salesPitchDownloadButton" onClick={() => window.open(location.additionalInfo.fielURL, '_blank')}>
        <ArrowDownIconComp/> 
        Download Sales Pitch
      </button>

      <div className='reporting_tooltip_content'>
        <h4>Activity Type</h4>
        <h5>{location?.additionalInfo?.activity_type_name}</h5>
      </div>

      <div className='reporting_tooltip_content'>
        <h4>Group</h4>
        <h5>{location?.additionalInfo?.group_name}</h5>
      </div>

      <div className='reporting_tooltip_content'>
        <h4>Latitude</h4>
        <h5>{location?.additionalInfo?.Latitude}</h5>
      </div>

      <div className='reporting_tooltip_content'>
        <h4>Longitude</h4>
        <h5>{location?.additionalInfo?.Longitude}</h5>
      </div>

      <div className='reporting_tooltip_content'>
        <h4>Category</h4>
        <h5>{location?.additionalInfo?.category_name}</h5>
      </div>

      <div className='reporting_tooltip_content'>
        <h4>Sub-Category</h4>
        <h5>{location?.additionalInfo?.sub_category_name}</h5>
      </div>

      {state &&
        state.length &&
        state.map((group) => {
          return group && group.fields && group.fields.length && group.fields.map((item)=>{
            return (
              <>
                {item?.fieldType !== 'image' && item?.fieldType !== 'audio' && item?.fieldType !== 'video' && item?.fieldType !== 'attachment'
                  ? (
                    <div className='reporting_tooltip_content'>
                      <h4>{item?.label}</h4>
                      {item?.fieldType === 'date' 
                        ? <h5>
                            {item.value 
                              ? (moment(item.value).format("DD-MM-YY")) 
                              : "-" }
                          </h5>
                        : <h5>
                            {item.value 
                              ? (Array.isArray(item.value) ? (item.value.length && item.value.join(", ")) : (item.value) ) 
                              : "-" }
                          </h5>
                      }
                    </div> 
                    )
                  : (
                    <div className='reporting_tooltip_content'>
                      <h4>{item?.label}</h4>
                      {item.value
                        ? <div
                            className={`flex  action_button cursor-pointer px-3 py-1 rounded paragraphs_font ${ThemeColor
                              ? "text-black edit-button hover:text-blue-500"
                              : "text-white view-button-dark hover:text-blue-300"
                              }`}
                          >
                            <div
                              className="flex items-center"
                              onClick={() => window.open(item?.value, "_blank")}
                            >
                              {/* <img src={EyeFilled} alt="import_icon" className="px-1  static" /> */}

                              {item?.fieldType === 'audio' 
                                ? "Listen Audio" 
                                : item?.fieldType === 'image' 
                                  ? "View Image" 
                                  : item?.fieldType === 'attachment' 
                                    ? "View Attachment" 
                                    : "Watch Video" }
                            </div>
                          </div>
                        : <h5>-</h5>}
                    </div>
                  )} 
              </>
            )

              // <div className='reporting_tooltip_content'>
              //   <h4>{item?.label}</h4>
              //   <h5>{item?.value}</h5>
              // </div>
            
            })

        })
          
        }
    </div>
  );
};

export default ToolTip;