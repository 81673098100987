import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";

export const RadioField = ({
  className = "form-control",
  name = "",
  id = "",
  disabled = false,
  checked = false,
  label = "",
  value = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  labelClass,
  ThemeColor,
  activity,
  customForm,
}) => {
  return (
    <div className={`relative w-full`}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        className={className}
        onChange={changeHandler}
        onBlur={blurHandler}
        disabled={disabled}
        required={required}
      />
      {label && (
        <label
          className={`ml-2 ${
            activity
              ? "text-black text-xs paragraphs_font"
              : `text-xs paragraphs_font ${
                  labelClass
                    ? !ThemeColor
                      ? "text-white"
                      : "text-black"
                    : "text-white"
                }`
          }`}
          htmlFor={name}
        >
          {label}{" "}
          {label && required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      {errorMessage && (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      )}
    </div>
  );
};

RadioField.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.any,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  ThemeColor: PropTypes.string,
  activity: PropTypes.bool,
  customForm: PropTypes.bool,
};
