import React from "react";

export const CheckGroup = ({ checkboxes, onChange, layout }) => (
  <>
    {checkboxes.map((checkbox) => (
      <div key={checkbox.id} className="mt-3 ">
        <input
          type="checkbox"
          className="cursor-pointer"
          id={checkbox.id}
          onChange={() => onChange(checkbox.id)}
        />
        <label htmlFor={checkbox.id} className="paragraphs_font ml-1 ">
          <span className="text-base">{checkbox.label}</span>
          <span className="text-sm text-blue-500">&nbsp;({checkbox.description})</span>
        </label>
      </div>
    ))}
  </>
);
