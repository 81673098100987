import React from 'react';

const ArrowDownIconComp = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 15V1" stroke="#0073EE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.8334 10L10.0001 15.8333L4.16675 10" stroke="#0073EE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default ArrowDownIconComp;