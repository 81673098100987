import React from "react";
import PropTypes from "prop-types";

export const Rating = ({
  id,
  name,
  value,
  label,
  changeHandler,
  blurHandler,
  maxRating,
  required,
  disabled,
}) => {
  const handleRatingClick = (ratingValue) => {
    if (!disabled) {
      changeHandler({ target: { value: ratingValue, name } });
    }
  };

  const stars = [];
  for (let i = 1; i <= maxRating; i++) {
    if (i <= value) {
      stars.push(
        <span
          key={i}
          className="star filled"
          onClick={() => handleRatingClick(i)}
        >
          ★
        </span>
      );
    } else if (i - 0.5 <= value) {
      stars.push(
        <span
          key={i}
          className="star half-filled"
          onClick={() => handleRatingClick(i - 0.5)}
        >
          ★
        </span>
      );
    } else {
      stars.push(
        <span
          key={i}
          className="star"
          onClick={() => handleRatingClick(i)}
        >
          ★
        </span>
      );
    }
  }

  return (
    <div className="rating-container">
      {label !== "" ? (
        <div className={`mb-1`}>
          <label
            className={`text-xs paragraphs_font text-white`}
            htmlFor={name}
          >
            {label}{" "}
            {label !== "" && required ? (
              <span className="text-red-500 ml-1">*</span>
            ) : (
              ""
            )}
          </label>
        </div>
      ) : (
        ""
      )}
      <div className="stars" onBlur={blurHandler}>
        {stars}
      </div>
    </div>
  );
};

Rating.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  blurHandler: PropTypes.func,
  maxRating: PropTypes.number.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

Rating.defaultProps = {
  maxRating: 5,
  required: false,
  disabled: false,
};

export default Rating;
