import { getDynamicDates } from "utils/helpers/dashboard";
import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";
import { ActivityTypeEnum } from "utils/constants/DDsDashboard";

export default function DDSFiltersReducer(state = StoreState.DDsFilters, action) {
  switch (action.type) {
    case ActionType.UPDATEDDSFILTERS:
      const s = cloneDeep(state);
      s.organization = action?.data?.organization;
      s.activityType = action?.data?.activityType;
      s.dateRange = action?.data?.dateRange;
      s.program = action?.data?.program;
      s.activity = action?.data?.activity;
      s.city = action?.data?.city;
      s.pop = action?.data?.pop;
      s.geoFence = action?.data?.geoFence;
      s.Brand = action?.data?.Brand;
      s.Agency = action?.data?.Agency;
      s.fieldStaff = action?.data?.fieldStaff;
      s.fieldStaffGroup = action?.data?.fieldStaffGroup;
      s.Pak = action?.data?.Pak;
      s.Sec = action?.data?.Sec;
      return s;

    case ActionType.RESETDDSFILTERS:
      const s1 = cloneDeep(state);
      s1.program = [];
      s1.activity = [];
      s1.city = [];
      s1.pop = [];
      s1.dateRange = getDynamicDates();
      s1.geoFence = [];
      s1.Brand = [];
      s1.Agency = [];
      s1.fieldStaff = [];
      s1.fieldStaffGroup = [];
      s1.Pak = [];
      s1.Sec = [];
      return s1;

    case ActionType.HARDRESETDDSFILTERS:
      const s2 = cloneDeep(state);
      s2.organization = [];
      s2.activityType = s2.activityType;
      s2.program = [];
      s2.activity = [];
      s2.city = [];
      s2.pop = [];
      s2.dateRange = getDynamicDates();
      s2.geoFence = [];
      s2.Brand = [];
      s2.Agency = [];
      s2.fieldStaff = [];
      s2.fieldStaffGroup = [];
      s2.Pak = [];
      s2.Sec = [];
      return s2;

    case ActionType.ACTIVITYCHANGE:
      const s3 = cloneDeep(state);
      s3.organization = [];
      s3.activityType = ActivityTypeEnum.DTD;
      s3.program = [];
      s3.activity = [];
      s3.city = [];
      s3.pop = [];
      s3.dateRange = getDynamicDates();
      s3.geoFence = [];
      s3.Brand = [];
      s3.Agency = [];
      s3.fieldStaff = [];
      s3.fieldStaffGroup = [];
      s3.Pak = [];
      s3.Sec = [];
      return s3;

    default:
      return state;
  }
}
