import React from "react";
import pencil from "assets/pencil.svg";
import { useTranslation } from "react-i18next";
export const Information = ({
  title,
  ThemeColor,
  orgDetails,
  setOrganizationModalOpen,
  orgTypes,
}) => {
  const orgTypeName = orgTypes.filter((item) => {
    if (orgDetails?.organization_type === item.id) {
      return item;
    }
  });

  const Details = {
    Organization_Name: orgDetails?.name,
    Organization_Long_Name: orgDetails?.longName,
    Organization_Type: orgTypeName[0]?.name,
    Organization_Website: orgDetails?.website,
    Organization_Domain: orgDetails?.domain,
    Organization_email: orgDetails?.email,
  };
  const [t, i18n] = useTranslation("global");
  const transformValues = (item) => {
    let letter = "";
    letter = item?.replace(/[_*]+/g, " ");


    return letter;
  };

  return (
    <div
      className={`card_p rounded ${ThemeColor
        ? "bg-white card-board text-black"
        : "dashboard_card_bg_color border text-white"
        }`}
    >
      <div
        className={`information-header flex justify-between items-center px-12 py-2 ${ThemeColor
          ? "bg-for-light  text-black"
          : "dashboard_card_bg_color text-white"
          }`}
      >
        <div className="text-base font-semibold">{title}</div>
      </div>
      <div className="flex justify-end px-4 lg:px-12 pt-6 font-medium">
        <div className="flex  action_button cursor-pointer hover:text-blue-500  px-3 py-1 rounded">
          <div
            className="flex items-center"
            onClick={() => {
              setOrganizationModalOpen(true);
            }}
          >
            <img
              src={pencil}
              alt="import_icon"
              className="flex px-1 mt-0.5 static"
            />
            {t("action.edit")}
          </div>
        </div>
      </div>
      <div className=" flex justify-between pt-4 pb-8 px-4 lg:px-12 org-details overflow-x-auto ">
        {Object.keys(Details).map((item, i) => (
          <div className="lg:w-4/12">
            <p
              className={` font-semibold px-6 py-2 ${ThemeColor
                ? "bg-for-light boarder-description text-black"
                : "dashboard_card_bg_color border text-white"
                }`}
            >
              {transformValues(item)} *
            </p>
            <p className="item-detail px-6 py-2">{Details[item]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
