import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginLayout } from "components/LoginLayout";
import { DashboardLayout } from "components";
import { UserManagementLayout } from "components";
import { PublicRoute } from "./public";
import { getCookie } from "services/session/cookies";
import { PrivateRoute } from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginAction } from "store/actions/Auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const Login = lazy(() => import("../pages/Login"));
const Map = lazy(() => import("../pages/Map"));
const ResendVerficationLink = lazy(() =>
  import("../pages/ResendVerificationLink")
);
const VerifyAccount = lazy(() => import("../pages/Verify"));
const CreateNewAccount = lazy(() => import("../pages/CreateAccount"));
const UserRegistration = lazy(() => import("../pages/Register"));
const MainDashboard = lazy(() => import("../pages/Dashboards/MainDashboard"));
const UserManagementDashboard = lazy(() =>
  import("../pages/Dashboards/UserManagementDashboard")
);
const DDsDashboard = lazy(() =>
  import("../pages/DdsDashbord/DoorToDoor/index")
);
const UserManagement = lazy(() => import("../pages/UserManagement"));
const LandingPage = lazy(() => import("../pages/landingPage"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const OrganizationManagement = lazy(() =>
  import("../pages/OrganizationManagement")
);
const RolesManagement = lazy(() => import("../pages/RolesManagement"));
const GroupManagement = lazy(() => import("../pages/GroupManagement"));
const PermissionManagement = lazy(() =>
  import("../pages/PermissionManagement")
);
const FeatureManagement = lazy(() =>
  import("../pages/FeatureManagement")
);
const OrganizationView = lazy(() => import("../pages/ViewOrganization"));
const OrganizationDetails = lazy(() =>
  import("../pages/ViewOrganization/organizationDetails")
);
const PopManagement = lazy(() => import("../pages/PopManagement"));
const PopTypes = lazy(() => import("../pages/PopTypes"));
const PopRegion = lazy(() => import("../pages/PopRegion"));
const PopZone = lazy(() => import("../pages/PopZone"));
const PopTerritory = lazy(() => import("../pages/PopTerritory"));
const ActivityManagement = lazy(() => import("../pages/ActivitiesManagement"));
const CreateNewActivity = lazy(() =>
  import("../pages/ActivitiesManagement/createNewForm")
);
const CreateActivity = lazy(() =>
  import("../pages/ActivitiesManagement/ActivityForm")
);
const CreateGameActivity = lazy(() =>
  import("../pages/ActivitiesManagement/ActivityGame")
);
const CategoryManagement = lazy(() =>
  import("../pages/Category/CategoryManagement")
);
const SubCategoryManagement = lazy(() =>
  import("../pages/Category/SubCategory")
);
const ProductManagement = lazy(() => import("../pages/Category/Product"));
const GeoFence = lazy(() => import("../pages/GeoFence"));
const ActivityFormView = lazy(() =>
  import("../pages/ActivitiesManagement/ActivitySummary")
);
const GeoFenceForm = lazy(() => import("../pages/GeoFence/form"));
const StatusManagement = lazy(() => import("../pages/StatusManagement"));
const PopTypeView = lazy(() => import("../pages/PopTypes/PopTypeView"));
const AudioRecorder = lazy(() =>
  import("../pages/ActivitiesManagement/speechRecording")
);
const AudioUploader = lazy(() =>
  import("../pages/Translator/index")
);

const GroupAssignment = lazy(() =>
  import("../pages/GroupManagement/GroupAssignment")
);
const BranchAssignment = lazy(() =>
  import("../pages/GroupManagement/BranchAssignment")
);
const SiteManagement = lazy(() =>
  import("../pages/OrganizationManagement/SiteManagement")
);
const BranchManagement = lazy(() =>
  import("../pages/OrganizationManagement/BranchManagement")
);
const BranchDetails = lazy(() =>
  import("../pages/ViewOrganization/branchDetails")
);
const GroupUsers = lazy(() => import("../pages/ViewOrganization/GroupUsers"));
const RoleAssignment = lazy(() =>
  import("../pages/RolesManagement/RoleAssignment")
);
const PositionManagement = lazy(() =>
  import("../pages/PopManagement/PopPosition")
);
const Reporting = lazy(() => import("../pages/Reporting"));
const ReportingDetailedView = lazy(() =>
  import("../pages/Reporting/ReportingDetailedView")
);
const ActivityInProgram = lazy(() =>
  import("../pages/ActivitiesManagement/Activities")
);
const AccessDeneid = lazy(() => import("../pages/AccessDenied"));

const Monitoring = lazy(() => import("../pages/Monitoring"));

const MonitoringAttendance = lazy(() =>
  import("../pages/Monitoring/Attendance")
);

const DoorToDoor = lazy(() =>
  import("../pages/DdsDashbord/DoorToDoor")
);
const InStore = lazy(() =>
  import("../pages/DdsDashbord/InStore")
);
const SupervisorPerformance = lazy(() =>
  import("../pages/DdsDashbord/SupervisorPerformance")
);
const CustomerData = lazy(() =>
  import("../pages/DdsDashbord/SupervisorPerformance/CustomerData")
);
const TeamAccumulated = lazy(() =>
  import("../pages/DdsDashbord/SupervisorPerformance/TeamAccumulated")
);

const LoaderPage = lazy(() => import("../pages/LoaderPage"));
const GamingPage = lazy(() => import("../pages/Games"));
const Announcement = lazy(() => import("../pages/Announcement"));
const GiftManagement = lazy(() => import("../pages/GiftManagement"));

const AppRouter = () => {
  const dispatch = useDispatch()
  const userInformation = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (getCookie("accessToken") && userInformation?.length === 0) {
      const userInformation = JSON.parse(getCookie("user" ? "user" : {}));
      dispatch(LoginAction(userInformation));
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={"Loading"}>
        <Routes>
          <Route
            path="/login"
            element={<PublicRoute component={Login} layout={LoginLayout} />}
          />
          <Route
            path="/resend-verfication-link"
            element={
              <PublicRoute
                component={ResendVerficationLink}
                layout={LoginLayout}
              />
            }
          />
          <Route
            path="/verify-account"
            element={
              <PublicRoute component={VerifyAccount} layout={LoginLayout} />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PublicRoute component={PrivacyPolicy} layout={LoginLayout} />
            }
          />
          <Route
            path="/create-new-account"
            element={
              <PublicRoute component={CreateNewAccount} layout={LoginLayout} />
            }
          />
          <Route
            path="/main-dashboard"
            element={
              <PrivateRoute component={MainDashboard} layout={DashboardLayout} />
            }
          />
          <Route
            path="/dds-dashboard"
            element={
              <PrivateRoute
                component={DDsDashboard}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/dds-dashboard"
            element={
              <PrivateRoute
                component={DoorToDoor}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/in-store-dashboard"
            element={
              <PrivateRoute
                component={InStore}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/supervisor-performance-dashboard"
            element={
              <PrivateRoute
                component={SupervisorPerformance}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/team-accumulated-dashboard"
            element={
              <PrivateRoute
                component={TeamAccumulated}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/customer-data-dashboard"
            element={
              <PrivateRoute
                component={CustomerData}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/user-management-dashboard"
            element={
              <PrivateRoute
                component={UserManagementDashboard}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute
                component={UserManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/organization-management"
            element={
              <PrivateRoute
                component={OrganizationManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/roles-management"
            element={
              <PrivateRoute
                component={RolesManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/group-management"
            element={
              <PrivateRoute
                component={GroupManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/permission-management"
            element={
              <PrivateRoute
                component={PermissionManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/view-organization"
            element={
              <PrivateRoute
                component={OrganizationView}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/organization-details"
            element={
              <PrivateRoute
                component={OrganizationDetails}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/pop-type-management"
            element={
              <PrivateRoute
                component={PopManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/pop-types"
            element={
              <PrivateRoute component={PopTypes} layout={UserManagementLayout} />
            }
          />
          <Route
            path="/pop-region"
            element={
              <PrivateRoute component={PopRegion} layout={UserManagementLayout} />
            }
          />
          <Route
            path="/pop-zone"
            element={
              <PrivateRoute component={PopZone} layout={UserManagementLayout} />
            }
          />
          <Route
            path="/pop-territory"
            element={
              <PrivateRoute
                component={PopTerritory}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/program-management"
            element={
              <PrivateRoute
                component={ActivityManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/view-program-forms"
            element={
              <PrivateRoute
                component={CreateNewActivity}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/create-activity"
            element={
              <PrivateRoute
                component={CreateActivity}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/create-game-activity"
            element={
              <PrivateRoute
                component={CreateGameActivity}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/category-management"
            element={
              <PrivateRoute
                component={CategoryManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/subcategory-management"
            element={
              <PrivateRoute
                component={SubCategoryManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/product-management"
            element={
              <PrivateRoute
                component={ProductManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/geo-fence"
            element={
              <PrivateRoute component={GeoFence} layout={UserManagementLayout} />
            }
          />
          <Route
            path="/activity-form-view"
            element={
              <PrivateRoute
                component={ActivityFormView}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/geo-fence-form"
            element={
              <PrivateRoute
                component={GeoFenceForm}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/geo-fence-form/:id"
            element={
              <PrivateRoute
                component={GeoFenceForm}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/status-management"
            element={
              <PrivateRoute
                component={StatusManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/pop-type-view"
            element={
              <PrivateRoute
                component={PopTypeView}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/speech-checking"
            element={
              <PrivateRoute
                component={AudioRecorder}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/translator-upload"
            element={
              <PrivateRoute
                component={AudioUploader}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/group-assignment"
            element={
              <PrivateRoute
                component={GroupAssignment}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/branch-assignment"
            element={
              <PrivateRoute
                component={BranchAssignment}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/site-management"
            element={
              <PrivateRoute
                component={SiteManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/branch-management"
            element={
              <PrivateRoute
                component={BranchManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/branch-details"
            element={
              <PrivateRoute
                component={BranchDetails}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/group-users"
            element={
              <PrivateRoute
                component={GroupUsers}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/assign-role"
            element={
              <PrivateRoute
                component={RoleAssignment}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/pop-position"
            element={
              <PrivateRoute
                component={PositionManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/reporting"
            element={
              <PrivateRoute component={Reporting} layout={UserManagementLayout} />
            }
          />
          <Route
            path="/reporting/detail"
            element={
              <PrivateRoute
                component={ReportingDetailedView}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/activities-management"
            element={
              <PrivateRoute
                component={ActivityInProgram}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/feature-management"
            element={
              <PrivateRoute
                component={FeatureManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/monitoring"
            element={
              <PrivateRoute
                component={Monitoring}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/attendance"
            element={
              <PrivateRoute
                component={MonitoringAttendance}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/games"
            element={
              <PrivateRoute
                component={GamingPage}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/announcement"
            element={
              <PrivateRoute
                component={Announcement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route
            path="/gifts"
            element={
              <PrivateRoute
                component={GiftManagement}
                layout={UserManagementLayout}
              />
            }
          />
          <Route path="/home" element={<LoaderPage />} />
          <Route path="/user-registration" element={<UserRegistration />} />
          <Route path="/access-deneid" element={<AccessDeneid />} />
          <Route path="/map" element={<Map />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        theme="light"
        limit={3}
        style={{ marginTop: "50px" }}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover={true}
        progress={undefined}
      />
    </BrowserRouter>
  );
}

export default AppRouter;
