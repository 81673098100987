import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";
import { GETSTAFFATTENDANCE } from "../actions/Types";

export default function MonitoringReducer(
  state = StoreState.monitoring,
  action
) {
  switch (action.type) {
    case ActionType.GETSTAFFATTENDANCE:
      var s = cloneDeep(state);
      s.staffAttendance = action?.data?.data;
      return s;
    default:
      return state;
  }
}
