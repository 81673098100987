import { ACTIVITYCHANGE, HARDRESETDDSFILTERS, RESETDDSFILTERS, UPDATEDDSFILTERS } from "./Types";


export function updateDDsFilters(data) {
  return {
    type: UPDATEDDSFILTERS,
    data,
  };
}

export function resetDDsFilters(data) {
  return {
    type: RESETDDSFILTERS,
    data,
  };
}

export function hardResetDDsFilters(data) {
  return {
    type: HARDRESETDDSFILTERS,
    data,
  };
}

export function activityChange(data) {
  return {
    type: ACTIVITYCHANGE,
    data,
  };
}
