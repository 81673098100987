import moment from "moment";

export const Columns = [
  {
    title: "Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Status",
    dataIndex: "status",

    key: "status",
  },
  {
    title: "Created",
    dataIndex: "created_date",
    key: "created_date",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const organizationColumns = [
  {
    title: "Organization Type",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",

    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const rolesColumns = [
  {
    title: "Role Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
  {
    title: "Status",
    dataIndex: "status",

    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const GroupColumns = [
  {
    title: "Group Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const registerUser = {
  userName: "",
  email: "",
  password: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  idenityId: "",
  faxNumber: "",
  country: "",
  city: "",
  postalAddress: "",
  gender: "",
  orgId: 0,
  userType: 0,
  gender: "",
  imei: "",
  status: "",
  checked: "",
  logo: "",
  errorMessages: {},
  usersType: [],
  organizations: [],
};

export const locationsColumns = [
  {
    title: "Country",
    dataIndex: "countryName",
    // key: "countryName",
    render(text, record) {
      return {
        props: {
          style: { background: "white" },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "City",
    dataIndex: "cityName",
    key: "cityName",
  },
  {
    title: "Location",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const LocationJson = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Country",
      name: "countryId",
      xmlName: "countryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Country",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select Country",
          value: "",
        },
      ],
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "City",
      name: "cityId",
      xmlName: "cityId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select City",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select City",
          value: "",
        },
      ],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Location Type",
      name: "type",
      xmlName: "type",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Location Type",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select Type",
          value: "",
        },
        {
          label: "Site",
          value: "site",
        },
        {
          label: "Branch",
          value: "branch",
        },
      ],
    },
    {
      id: "4",
      fieldType: "text",
      isRequired: true,
      isUpdate: true,
      name: "name",
      label: "Location Address",
      xmlName: "name",
      popupText: "",
      helpNote: "",
      placeHolder: "Location Address",
      errorMessage: "Enter valid Location Address",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    // {
    //   id: "5",
    //   fieldType: "checkbox",
    //   isRequired: false,
    //   isUpdate: true,
    //   name: "status",
    //   label: "Staus",
    //   xmlName: "status",
    //   popupText: "",
    //   helpNote: "",
    //   placeHolder: "",
    //   errorMessage: "Field Required !",
    //   value: false,
    // },
  ],
};

export const organizationEdit = {
  submit_url: "/auth/login",
  form: [
    {
      id: "1",
      fieldType: "uploadImage",
      isRequired: true,
      isUpdate: false,
      name: "logo",
      label: "Organization Logo",
      xmlName: "logo",
      popupText: "",
      helpNote: "",
      placeHolder: "Organization Logo",
      errorMessage: "Select Organization logo",
      regex: "",
      value: "",
    },
    {
      id: "0",
      fieldType: "text_",
      isRequired: false,
      isUpdate: false,
      name: "hidden",
      label: "hidden",
      xmlName: "hidden",
      popupText: "",
      helpNote: "",
      placeHolder: "hidden",
      errorMessage: "hidden",
      regex: "",
      value: "",
      hidden: true,
    },
    {
      id: "2",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      name: "name",
      label: "Name",
      xmlName: "name",
      popupText: "",
      helpNote: "",
      placeHolder: "Organization name",
      errorMessage: "Enter valid Organization name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "Organization long name",
      name: "longName",
      xmlName: "longName",
      popupText: "",
      errorMessage: "Enter valid Organization long name",
      placeHolder: "Organization long name",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
    {
      id: "6",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "Website",
      name: "Organization website",
      xmlName: "website",
      popupText: "",
      errorMessage: "Enter valid Organization website",
      placeHolder: "Organization website",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
    {
      id: "7",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "domain",
      name: "Organization domain",
      xmlName: "domain",
      popupText: "",
      errorMessage: "Enter valid Organization domain",
      placeHolder: "Organization domain",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
    {
      id: "4",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      isEdit: false,
      label: "Organization Type",
      name: "Organization type",
      xmlName: "organizationType",
      popupText: "",
      errorMessage: "Enter valid Organization Type",
      placeHolder: "Organization Type",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "5",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      isEdit: false,
      label: "Partner Organization",
      name: "partnerOrganization",
      xmlName: "partnerOrganization",
      popupText: "",
      errorMessage: "Enter valid partner Organization",
      placeHolder: "Partner Organization",
      helpNote: "",
      value: "",
      regex: "",
      hidden: true,
      options: [],
    },
  ],
};

export const siteJson = {
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      name: "siteName",
      autoFocus: true,
      label: "Site Name",
      xmlName: "siteName",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter site name",
      errorMessage: "Enter valid Site Name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Organization",
      name: "organizationId",
      xmlName: "organizationId",
      popupText: "",
      errorMessage: "Enter valid Organization",
      placeHolder: "Select Organization",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Country",
      name: "countryId",
      xmlName: "countryId",
      popupText: "",
      errorMessage: "Enter valid Country",
      placeHolder: "Select country",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "4",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "City",
      name: "cityId",
      xmlName: "cityId",
      popupText: "",
      errorMessage: "Enter valid City",
      placeHolder: "Select City",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      name: "siteDescription",
      label: "Description",
      xmlName: "siteDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Please enter site description",
      errorMessage: "Enter Description",
      regex: "",
      value: "",
      cols: "73",
      rows: "8",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
  ],
};

export const branchJson = {
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      autoFocus: true,
      isUpdate: false,
      name: "branchName",
      label: "Branch Name",
      xmlName: "branchName",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter Branch Name",
      errorMessage: "Enter valid Branch Name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Organization",
      name: "organizationId",
      xmlName: "organizationId",
      popupText: "",
      errorMessage: "Enter valid Organization",
      placeHolder: "Select Organization",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Site",
      name: "siteId",
      xmlName: "siteId",
      popupText: "",
      errorMessage: "Enter valid Site",
      placeHolder: "Select Site",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      label: "Address",
      name: "branchAddress",
      xmlName: "branchAddress",
      popupText: "",
      helpNote: "",
      placeHolder: "Please enter branch address",
      errorMessage: "Enter Address",
      regex: "",
      value: "",
      cols: "73",
      rows: "4",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
  ],
};


export const UserTypeEnum = {
  ADMIN: 1,
  ORGANIZATIONUSER: 2,
  PERTNER: 3,
  FIELDSTAFF: 4,
  SUPERVISORSURVEILLANCE: 5
}