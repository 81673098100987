// import React, { useState } from "react";
// import { DatePicker } from "@nextui-org/react";
// import { motion } from "framer-motion";

// export const DatePickerField = ({
//   name = "",
//   id = "",
//   value = "",
//   label = "",
//   changeHandler,
//   required = false,
//   placeholder = "",
//   labelClass,
//   ThemeColor,
//   activity = false,
// }) => {
//   const [isFocused, setIsFocused] = useState(false);
//   const dateValue = value ? new Date(value) : null;

//   return (
//     <div className="relative input-Datefield-container w-full">
//       <motion.label
//         initial={{ y: 0, scale: 1 }}
//         animate={{
//           y: isFocused || value ? -8 : 0,
//           x: isFocused || value ? 0 : 0,
//           scale: isFocused || value ? 0.75 : 1,
//         }}
//         transition={{ duration: 0.3 }}
//         className={`absolute left-2 top-2 transition-all ${
//           activity
//             ? "text-black text-xs paragraphs_font"
//             : `text-xs paragraphs_font ${
//                 labelClass
//                   ? !ThemeColor
//                     ? "text-white"
//                     : "text-black"
//                   : "text-white"
//               }`
//         }`}
//         style={{ pointerEvents: "none" }}
//       >
//         {label} {required ? <span className="text-red-600">*</span> : ""}
//       </motion.label>

//       <DatePicker
//         value={dateValue}
//         onChange={(newDate) => {
//           setIsFocused(true);
//           const selectedDate = new Date(
//             newDate.year,
//             newDate.month - 1,
//             newDate.day
//           );
//           changeHandler(selectedDate);
//         }}
//         onFocus={() => setIsFocused(true)}
//         onBlur={() => setIsFocused(false)}
//         className="pl-1 pt-6 pb-2 border rounded w-full"
//       />
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import { DatePicker } from "@nextui-org/react";
import { parseDate } from "@internationalized/date";
import moment from "moment";
import { DEFAULT_DATE } from "utils/constants/constatns";

export const DatePickerField = ({
  type = "text",
  name = "",
  id = "",
  value = "",
  label = "",
  changeHandler,
  max,
  min,
  required = false,
  placeholder = "",
  labelClass,
  ThemeColor,
}) => {
  // Convert value to a date if it's valid, otherwise set to null
  const initialDate = value ? moment(value, "YYYY-MM-DD", true).isValid() ? moment(value).toDate() : null : null;

  const [isFocused, setIsFocused] = useState(false);
  const [date, setDate] = useState(value ? parseDate(value || DEFAULT_DATE) : null); // Initialize with safe date value
  useEffect(() => {
    setDate(value ? parseDate(value || DEFAULT_DATE) : null);
  }, [value]);

  // Handle onChange
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update internal state
    changeHandler(moment(newDate, "YYYY-MM-DD").format("YYYY-MM-DD")); // Trigger parent changeHandler with formatted date
  };

  return (
    <div className="relative input-Datefield-container w-full">
      <DatePicker
        clearable
        autoComplete="off"
        type={type}
        name={name}
        maxValue={max}
        minValue={min}
        id={id}
        value={date} // Use the local date state
        label={label}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleDateChange} // Call the internal handler
        isRequired={required}
        className={`rounded w-full text-white outline-none calender-icon ${isFocused ? "field-focused" : "field-label"
          }`}
      />
    </div>
  );
};

