import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { Select } from "../Select";
import sun_full from "../../assets/sun_full.png";
import { getCookie } from "services/session/cookies";
import WeatherComponent from "../Weather"
import { useSelector, useDispatch } from "react-redux";
import LanguageENG from '../../assets/language-eng.svg';
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "services/api/common";

  
export const UserGreetings = ({ title, description, className, icon }) => {
  const [language, setLanguage] = useState("");
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useState('')
  const [currentTime, setCurrentTime] = useState(moment().format("LT"))
  const selectedLanguage = useSelector((state) => state?.common?.Language);
  const onchangeHandler = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
    dispatch(ChangeLanguage(value));
  };
  let curDate = new Date();
  curDate = curDate.getHours();
  let greetings = "";
  if (curDate >= 1 && curDate < 12) {
    greetings = "grettings.goodMorning";
  } else if (curDate >= 12 && curDate < 19) {
    greetings = "grettings.goodAfternoon";
  } else {
    greetings = "grettings.goodEvening";
  }

  useEffect(() => {
    try {
      const user = JSON.parse(getCookie("user" ? "user" : {}));
      setUser(user);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const myFunction = () => {
    let currentTime = moment().format("LT");
    setCurrentTime(currentTime);
  };

  useEffect(() => {
    const intervalId = setInterval(myFunction, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="greetings flex justify-between text-white pl-6 ">
      <div>
        <p className="text-lg lg:text-2xl">
          {t(`${greetings}`)}! {user?.userName ? user?.userName : "User Name"}
        </p>
        <div className="paragraphs_font pt-1 text-sm lg:text-base font-thin">
          {moment().format("dddd , DD MMM YYYY")}
        </div>
      </div>
      <div>
        <div className="flex items-end flex-col">
          <div className="text-xs lg:text-sm">
            <span className="pr-2 flex items-center">
              <WeatherComponent />
            </span>
            {currentTime}
          </div>
          <div>
            <Select
              id="20"
              label=""
              className={`w-full h-8  outline-none paragraphs_font field_bg-blue text-xs lg:text-sm  pl-1 pr-5 text-white border font-normal language-select`}
              changeHandler={(event) => onchangeHandler(event.target.value)}
              value={selectedLanguage}
              name="usertype"
              options={[
                {
                  label: "English (US)",
                  value: "en",
                  icon: LanguageENG,
                },
                {
                  label: "Arabic (عربي)",
                  value: "ar",
                  icon: LanguageENG,
                },
              ]}
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
