import { REACT_APP_API_BASE_URL } from "../../utils/constants/constatns";
export const BASE_URL = REACT_APP_API_BASE_URL || "";

export const ENDPOINTS = {
  LOGIN: `${BASE_URL}/auth/login`,
  REGISTER: `${BASE_URL}/api/orgnization/register`,
  REGISTER_USER: `${BASE_URL}/api/user/register`,
  GENERATE_USER_ID: `${BASE_URL}/auth/register`,
  VERIFY_ACCOUNT: `${BASE_URL}/auth/user/account/verify/`,
  REGENERATE_TOKEN: `${BASE_URL}/auth/user/account/regenerateToken/`,
  GET_ALL_ORGANIZATIONS: `${BASE_URL}/api/orgnization/orgnization/all`,
  GET_ALL_USERS: `${BASE_URL}/api/user/user/all`,
  UPLOADFILE: `${BASE_URL}/api/upload/file`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  STATICTICS: `${BASE_URL}/api/user/user/statistics`,
  GROUP_STATICTICS: `${BASE_URL}/api/group/group/statistics`,
  PERMISON_STATICTICS: `${BASE_URL}/api/permission/permission/statistics`,
  GROUP_ASSIGN: `${BASE_URL}/api/user/has/group/multi`,
  FORM_ASSIGN: `${BASE_URL}/api/formn/has/group/location/assign-activity-staff`,
  USERTYPES: `${BASE_URL}/api/ut`,
  ORGANIZATIONSTYPES: `${BASE_URL}/api/ot`,
  ORGANIZATIONSTATS: `${BASE_URL}/api/orgnization/organizations/statistics`,
  ALLORGANIZATIONS: `${BASE_URL}/api/orgnization/orgnization/all`,
  CREATE_NEW_USER: `${BASE_URL}/api/user/new/user`,
  UPDATE_USER: `${BASE_URL}/api/user`,
  CREATE_NEW_GROUP: `${BASE_URL}/api/group`,
  CREATE_NEW_PERMISSION: `${BASE_URL}/api/permission`,
  CREATE_NEW_ROLE: `${BASE_URL}/api/role`,
  GET_ALL_GROUP: `${BASE_URL}/api/group`,
  GET_ALL_ROLES: `${BASE_URL}/api/role`,
  ROLE_STATICTICS: `${BASE_URL}/api/role/role/statistics`,
  ROLE_ASSIGN: `${BASE_URL}/api/group/has/role/multi`,
  GET_ALL_PERMISONS: `${BASE_URL}/api/permission`,
  GET_ALL_PERMISONS_TYPE: `${BASE_URL}/api/pt`,
  UPLOAD_CSV_FILE: `${BASE_URL}/api/user/import-csv`,
  DELETE_USER_BY_ID: `${BASE_URL}/api/user/`,
  DELETE_USER: `${BASE_URL}/api/user/multi/users`,
  GET_ALL_POP_TYPES: `${BASE_URL}/api/pop`,
  GET_POP_TYPES: `${BASE_URL}/api/poptype`,
  GET_POP_TYPES_STATS: `${BASE_URL}/api/poptype/poptype/statistics`,
  GET_POP_REGIONS: `${BASE_URL}/api/region`,
  GET_POP_ZONES: `${BASE_URL}/api/zone`,
  GET_POP_TERRITORIES: `${BASE_URL}/api/territory`,
  GET_ALL_POP_TYPE: `${BASE_URL}/api/poptype`,
  GET_ALL_CITY: `${BASE_URL}/api/city`,
  GET_FENCE_BY_ID: `${BASE_URL}/api/geofencing`,
  CRETAE_POP_ZONE: `${BASE_URL}/api/zone`,
  UPDATE_POP_ZONE: `${BASE_URL}/api/zone`,
  ZONE_STATS: `${BASE_URL}/api/zone/zone/statistics`,
  DELETE_POP_ZONE: `${BASE_URL}/api/zone`,
  CREATE_POP_TERRITORY: `${BASE_URL}/api/territory`,
  UPDATE_POP_TERRITORY: `${BASE_URL}/api/territory`,
  TERRITORY_STATS: `${BASE_URL}/api/territory/territory/statistics`,
  DELETE_POP_TERRITORY: `${BASE_URL}/api/territory`,
  GET_LOCATION_BY_ID: `${BASE_URL}/api/location/org`,
  GET_ORGANSATION_BY_ID: `${BASE_URL}/api/orgnization`,
  GET_POP_REGION_STATS: `${BASE_URL}/api/region/region/statistics`,
  GET_ALL_CATEGORIES: `${BASE_URL}/api/category`,
  GET_ALL_SUB_CATEGORIES: `${BASE_URL}/api/sub/category/cat`,
  GET_ALL_PRODUCTS_BY_SUBCATEGORY_ID: `${BASE_URL}/api/product/subcat`,
  CREATE_CATEGORY: `${BASE_URL}/api/category`,
  UPDATE_CATEGORY: `${BASE_URL}/api/category`,
  DELETE_CATEGORY: `${BASE_URL}/api/category`,
  GET_ALL_SUB_CATEGORIES_BY_ID: `${BASE_URL}/api/sub/category/cat`,
  CREATE_SUB_CATEGORY: `${BASE_URL}/api/Sub/category`,
  UPDATE_SUB_CATEGORY: `${BASE_URL}/api/Sub/category`,
  DELETE_SUB_CATEGORY: `${BASE_URL}/api/sub/category`,
  GET_ALL_PRODUCTS_BY_ID: `${BASE_URL}/api/product/subcat`,
  CREATE_PRODUCT: `${BASE_URL}/api/product`,
  UPDATE_PRODUCT: `${BASE_URL}/api/product`,
  DELETE_PRODUCT: `${BASE_URL}/api/product`,
  GET_ALL_PROGRAMS: `${BASE_URL}/api/program`,
  CREATE_NEW_PROGRAMS: `${BASE_URL}/api/program`,
  CREATE_GEO_FENCE: `${BASE_URL}/api/geofencing`,
  DELETE_PROGRAMS: `${BASE_URL}/api/program`,
  GET_ORGANIZATIONS_BY_TYPE_ID: `${BASE_URL}/api/orgnization/type`,
  GET_ORGANIZATION_DETAILS: `${BASE_URL}/api/orgnization/orgnization`,
  GET_ORGANIZATION_STATS: `${BASE_URL}/api/location/statistics`,
  GET_ORGANIZATION_LOCATIONS: `${BASE_URL}/api/location/org`,
  CREATE_LOCATIONS: `${BASE_URL}/api/location`,
  GET_ALL_COUNTRIES: `${BASE_URL}/api/country`,
  DELETE_LOCATIONS: `${BASE_URL}/api/location`,
  GET_FORMS_BY_PROGRAM_ID: `${BASE_URL}/api/form/activity`,
  CREATE_ACTIVITY_FORM: `${BASE_URL}/api/form/assign`,
  DELETE_FORM: `${BASE_URL}/api/form`,
  GET_ACTIVITY_FORM_BY: `${BASE_URL}/api/form`,
  GET_GEO_FENCE: `${BASE_URL}/api/geofencing`,
  GET_ACTIVITY_FORM: `${BASE_URL}/api/form`,
  GET_USERS_BY_GROUP: `${BASE_URL}/api/user/has/group/groupId`,
  UPDATE_ASSIGN: `${BASE_URL}/api/user/has/group`,
  GET_GROUP_BY_ROLE: `${BASE_URL}/api/group/has/role/roleId`,
  UPDATE_ROLE: `${BASE_URL}/api/group/has/role`,
  GET_ALL_CATEGORIES_BY_ORG_ID: `${BASE_URL}/api/category/org`,
  GET_POP_TYPES_BY_POP_ID: `${BASE_URL}/api/pop/poptype`,
  DELETE_POP_BY_ID: `${BASE_URL}/api/pop`,
  SPEECH_TRANSCRIE: `${BASE_URL}/api/formresponse/transcribe`,
  CHANGE_GROUP_STATUS: `${BASE_URL}/api/group/multi/group/status`,
  UNASSIGN_USERS_GROUP: `${BASE_URL}/api/user/has/group/multi/user/has/groups`,
  CHANGE_POP_MANAGEMENT_STATUS: `${BASE_URL}/api/pop/multi/pop/status`,
  CHANGE_POP_TYPE_STATUS: `${BASE_URL}/api/poptype/multi/poptype/status`,
  CHANGE_POP_ZONE_STATUS: `${BASE_URL}/api/zone/multi/zone/status`,
  CHANGE_POP_REGION_STATUS: `${BASE_URL}/api/region/multi/region/status`,
  CHANGE_ROLES_STATUS: `${BASE_URL}/api/role/multi/role/status`,
  CHANGE_USER_STATUS: `${BASE_URL}/api/user/multi/user/status`,
  ORGANIZATION_SITES: `${BASE_URL}/api/site/organization`,
  SITE_BRANCHES: `${BASE_URL}/api/branch/site`,
  VIEW_BRANCHES: `${BASE_URL}/api/group/has/branch/branchId`,
  GET_USERS_BY_GROUP_ID: `${BASE_URL}/api/user/has/group/groupId`,
  SITE_STATS: `${BASE_URL}/api/site/site/statistics`,
  GET_ALL_SITES: `${BASE_URL}/api/site`,
  CREATE_SITE: `${BASE_URL}/api/site`,
  CHANGE_SITE_STATUS: `${BASE_URL}/api/site/multi/site/status`,
  GET_ALL_BRANCH: `${BASE_URL}/api/branch`,
  GET_BRANCH_STATS: `${BASE_URL}/api/branch/branch/statistics`,
  CHANGE_BRANCH_STATUS: `${BASE_URL}/api/branch/multi/branch/status`,
  CREATE_BRANCH: `${BASE_URL}/api/branch`,
  UPDATE_BRANCH: `${BASE_URL}/api/branch`,
  ASSIGN_GROUP_TO_BRANCH: `${BASE_URL}/api/group/has/branch/multi`,
  ASSIGN_ROLE_TO_GROUP: `${BASE_URL}/api/group/has/role/multi`,
  CHANGE_ACTIVITY_STATUS: `${BASE_URL}/api/program/multi/program/status`,
  GET_PERMISSION: `${BASE_URL}/api/all/permission`,
  GET_MODULES: `${BASE_URL}/api/permission/module`,
  GET_APPS: `${BASE_URL}/api/permission/app/module`,
  APP_OPTIONS: `${BASE_URL}/api/permission/app/option/app`,
  ASSIGN_PERMISSION: `${BASE_URL}/api/role/has/permission/multi`,
  ASSIGN_PERMISSION_: `${BASE_URL}/api/role/has/permission`,
  ORGANIZATION_STATUS: `${BASE_URL}/api/orgnization/multi/organization/status`,
  GET_USER_PERMISSIONS: `${BASE_URL}/api/user/user/permissions`,
  CHANGE_CATEGORY_STATUS: `${BASE_URL}/api/category/multi/category/status`,
  CHANGE_SUBCATEGORY_STATUS: `${BASE_URL}/api/sub/category/multi/subcategory/status`,
  CHANGE_PRODUCT_STATUS: `${BASE_URL}/api/product/multi/product/status`,
  GET_REGION_OF_SITE: `${BASE_URL}/api/region/site`,
  GET_ZONE_OF_REGION: `${BASE_URL}/api/zone/region`,
  GET_TERIIORIES_OF_ZONE: `${BASE_URL}/api/territory/zone`,
  CREATE_POSITION: `${BASE_URL}/api/pop/position`,
  UPDATE_PROGRAM: `${BASE_URL}/api/program`,
  ALL_SUB_CATEGORY: `${BASE_URL}/api/sub/category`,
  ALL_PRODUCTS: `${BASE_URL}/api/product`,
  UNASSIGN_ROLES: `${BASE_URL}/api/group/has/role/multi/user/has/groups`,
  UPDATE_ACTIVITY_FORM: `${BASE_URL}/api/form`,
  GET_ALL_UNASSIGNED_USERS: `${BASE_URL}/api/user/user/Unassigned/all`,
  GET_ALL_UNASSIGNED_GROUPS: `${BASE_URL}/api/group/Unassigned`,
  CHANGE_POP_TERRITORY_STATUS: `${BASE_URL}/api/territory/multi/territory/status`,
  GET_ALL_ACTIVITIES: `${BASE_URL}/api/activity/program`,
  GET_ALL_ACTIVITIY_TYPES: `${BASE_URL}/api/activity/type`,
  CREATE_NEW_ACTIVITY: `${BASE_URL}/api/activity`,
  UPDATE_ACTIVITY: `${BASE_URL}/api/activity`,
  GET_SUPER_ADMIN_REPORTING: `${BASE_URL}/api/form/records/filters`,
  GET_SUPER_ORGANIZATION_REPORTING: `${BASE_URL}/api/superOrganization/reporting`,
  GET_REPORTING_DETAIL: `${BASE_URL}/api/formresponse/response/filters`,
  GET_REPORTING_DETAIL_MAP: `${BASE_URL}/api/detail/reporting/map`,
  GET_REPORTING_DETAIL_FILTERS: `${BASE_URL}/api/formresponse/filters/for/response`,
  GET_FILTERS_DROPDOWN_REPORTING: `${BASE_URL}/api/form/filters/for/records`,
  UNASSIGN_STAFF_GROUP: `${BASE_URL}/api/group/has/branch/multi/user/has/groups`,
  FORM_RESPONSE_REPORTING: `${BASE_URL}/api/formresponse`,
  GET_STAFF_ATTENDANCE: `${BASE_URL}/api/attendance/attendance/by/filters`,
  UPDATE_ATTENDANCE: `${BASE_URL}/api/attendance`,
  GET_FILTERS_DROPDOWN_MONITORING: `${BASE_URL}/api/user/filters/for/monitoring`,
  GET_USERS_BY_ORGID: `${BASE_URL}/api/user/usertype`,
  GET_PERMISSIONS_BY_ORGID: `${BASE_URL}/api/role/has/permission/roleId`,
  VERIFY_ATTENDANCE: `${BASE_URL}/api/attendance/compare`,
  USER_MONITORING: `${BASE_URL}/api/user/monitoring/by/filters`,
  GET_ALL_GAMES: `${BASE_URL}/api/game`,
  CRETAE_GAMES: `${BASE_URL}/api/game`,
  UPDATE_GAME: `${BASE_URL}/api/game`,
  GET_ALL_GIFT: `${BASE_URL}/api/gift`,
  CREATE_GIFT: `${BASE_URL}/api/gift`,
  UPDATE_GIFT: `${BASE_URL}/api/gift`,
  DELETE_GAME: `${BASE_URL}/api/game/multi/games`,
  DELETE_GIFT: `${BASE_URL}/api/gift/multi/gifts`,
  GET_GROUPS_BY_ORGANIZATION: `${BASE_URL}/api/group/organization`,
  DDS_DASHBOARD_FILTER: `${BASE_URL}/api/dashboard/filters/for/dashboard`,
  DDS_CONSUMED_VS_PLANNED: `${BASE_URL}/api/dashboard/activity/days/stats`,
  TARGET_VS_REACH: `${BASE_URL}/api/dashboard/total/productive/knocks`,
  TARGET_VS_REACH_CHART: `${BASE_URL}/api/dashboard/target/reach`,
  CITY_BREAKDOWN: `${BASE_URL}/api/dashboard/city/wise/breakdown`,
  ACTIVITY_GLIMPSE: `${BASE_URL}/api/dashboard/activity/glimpes`,
  TOTAL_VS_PRODUCTIVE: `${BASE_URL}/api/dashboard/total/productive/knocks`,
  CARD_STATS: `${BASE_URL}/api/dashboard/target/cards/stats`,
  CARD_STATS_YTD: `${BASE_URL}/api/dashboard/ytd/target/cards/stats`,
  CARD_STATS_INSTORE: `${BASE_URL}/api/dashboard/instore/target/cards/stats`,
  GOOGLE_DDS: `${BASE_URL}/api/dashboard/activities/fieldstaff/count`,
  DEMO_PROVIDER: `${BASE_URL}/api/dashboard/count/demo/sale`,
  DEAL_OVERVIEW: `${BASE_URL}/api/dashboard/deals/overview`,
  SALES_OVERVIEW: `${BASE_URL}/api/dashboard/sales/overview`,
  COMPETITION_BRAND: `${BASE_URL}/api/dashboard/brand/breakdown`,
  COMPETITION_BREAKDOWN: `${BASE_URL}/api/dashboard/competition/brand`,
  GIFT_MERCHANDISING: `${BASE_URL}/api/dashboard/gift/merchandising`,
  SALES_BRAND: `${BASE_URL}/api/dashboard/highest/sale/brand`,
  TRIAL_REACH: `${BASE_URL}/api/dashboard/trial/reach`,
  FILTER_PROGRAM: `${BASE_URL}/api/dashboard/program/org`,
  FILTER_ACTIVITY: `${BASE_URL}/api/dashboard/activity/program`,
  FILTER_CITY: `${BASE_URL}/api/dashboard/city/activity`,
  FILTER_GEO_POP: `${BASE_URL}/api/dashboard/geofence/pop`,
  FILTER_TIRES: `${BASE_URL}/api/dashboard/tiers`,
  FILTER_BRANDS: `${BASE_URL}/api/dashboard/brands`,
  FILTER_USERS: `${BASE_URL}/api/dashboard/users`,
  FILTER_TIRES: `${BASE_URL}/api/dashboard/tiers`,
  GET_ALL_TIRES: `${BASE_URL}/api/tiers`,
  VOICE_UPLOADER: `${BASE_URL}/api/formresponse/translate`
};

export const REQUEST_TYPES = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
};
