import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function GameReducer(state = StoreState.game, action) {
  switch (action.type) {
    case ActionType.GETALLGAMELISTING:
      var s = cloneDeep(state);
      s.listing = action?.data?.data;
      return s;
    case ActionType.GETALLGIFTLISTING:
      var s1 = cloneDeep(state);
      s1.giftListing = action?.data?.data;
      return s1;
    default:
      return state;
  }
}
