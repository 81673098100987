export const DashboardOptions = [
    {
        value: "Door-to-Door",
        slag: 1,
        link: '/dds-dashboard',
        linkSlags: ['/dds-dashboard']
    },
    {
        value: "Instore",
        slag: 2,
        link: "/in-store-dashboard",
        linkSlags: ['/in-store-dashboard']

    },
    // {
    //     value: "Out-of-Home",
    //     slag: 3
    // },
    {
        value: "Supervisor Performance",
        slag: 4,
        link: '/supervisor-performance-dashboard',
        linkSlags: ['/supervisor-performance-dashboard', '/team-accumulated-dashboard', '/customer-data-dashboard']
    },
]

export const ActivityTypeEnum = {
    DTD: 1,
    INSTORE: 2,
    OUTOFHOMEMONITORING: 3,
    SUPERVISORPERFORMANCE: 4,
}