import AuthReducer from "./AuthReducer";
import CommonReducer from "./CommonReducer";
import queriesReducer from './queries.reducer';
import UserManagementReducer from "./UserManagementReducer";
import POPManagementReducer from "./POPManagementReducer";
import CategoryManagementReducer from "./CategoriesMngReducer";
import ActivityManagementReducer from "./ActivityManagement";
import ApiCallStatusReducer from "./ApiCallStatusReducer";
import MonitoringReducer from "./Monitoring";
import { combineReducers } from "redux";
import GameReducer from "./GameReducer";
import FiltersReducer from "./FiltersReducer";
import DDSFiltersReducer from "./DDSFilterReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const ddsFiltersPersistConfig = {
  key: 'DDsFilters',
  storage,
};

const persistedDDSFiltersReducer = persistReducer(ddsFiltersPersistConfig, DDSFiltersReducer);

const rootReducer = combineReducers({
  auth: AuthReducer,
  common: CommonReducer,
  UserManagement: UserManagementReducer,
  POPManagemnt: POPManagementReducer,
  CategoryManagement: CategoryManagementReducer,
  activtyManagement: ActivityManagementReducer,
  MonitoringManagement: MonitoringReducer,
  GameManagement: GameReducer,
  ApiCallStatusReducer: ApiCallStatusReducer,
  Queries: queriesReducer,
  Filters: FiltersReducer,
  DDsFilters: persistedDDSFiltersReducer,
});

export default rootReducer;
