import React, { useState } from "react";
import { ColorPicker } from "antd";

export const PickColor = ({
  id,
  name,
  value,
  changeHandler,
  label,
  hidden,
}) => {
  return (
    <>
      {!hidden && (
        <>
          <div className="flex flex-col w-full h-14 text-white outline-none inputfield pt-2 flex pr-3 pl-3 ">
          <div>
            <p className="text-xs font-normal font-sans text-white">
              {label}
              <span className="text-red-500">*</span>
            </p>
          </div>
          <div className="flex flex-row justify-between bg">
            <p className="text-sm font-normal font-sans  text-white">{value ? value : "Choose Color"}</p>
            <input
              type="color"
              name={name}
              id={id}
              value={value}
              hidden={hidden}
              className="bg-dark-theme outline-none field w-6 -mt-2.5 rounded-lg"
              onChange={changeHandler}
            />
          </div>
          </div>
        </>
      )}
    </>
  );
};
