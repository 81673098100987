import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "components"; 
import {DownloadCSV} from "components"; 
import { Select, Checkbox } from "antd";

const { Option } = Select;

export const DataTableWithExport = ({ Columns, data }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(
    Columns.map((column) => column.key)
  );
  const ThemeColor = useSelector((state) => state?.common?.Theme);

  const handleColumnSelection = (selectedValues) => {
    setSelectedColumns(selectedValues);
  };

  const getSelectedColumnData = () => {
    return data.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnKey) => {
        rowData[columnKey] = row[columnKey];
      });
      return rowData;
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select columns to display"
          defaultValue={selectedColumns}
          onChange={handleColumnSelection}
        >
          {Columns.map((column) => (
            <Option key={column.key} value={column.key}>
              {column.title}
            </Option>
          ))}
        </Select>
      </div>
      <DataTable
        Columns={Columns.filter((column) =>
          selectedColumns.includes(column.key)
        )}
        data={data}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        withOutCheckBox={false}
        handleSelectIds={(ids) => console.log(ids)}
      />
      <div style={{ marginTop: 16, textAlign: "right" }}>
        <DownloadCSV
          data={getSelectedColumnData()}
          filename="table_data.csv"
          buttonText="Export Table Data to CSV"
        />
      </div>
    </div>
  );
};


