export const AnimatedLogo = ({ size, loaderText, className = "loader" }) => {
  return (
    <div className="bg-dark-theme h-full flex items-center justify-center">
      <div className="w-full">
        <svg
          height="100"
          stroke="#0073EE"
          stroke-width="2"
          class="text-line"
          width="100%"
        >
          <text x="50%" dominant-baseline="middle" text-anchor="middle" y="50%">
            IntelyPro
          </text>
        </svg>
      </div>
    </div>
  );
};
